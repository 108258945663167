import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Logo from 'components/atoms/logo'
import Layout from 'templates/layout'
import SEO from 'components/seo'

export default ({
  data: {
    markdownRemark: {
      frontmatter: { image1, image2, image3, image4, image5, image6 },
    },
  },
}) => (
  <Layout hideHeader>
    <SEO />
    <div className="t-index">
      <div className="t-index__section -no1">
        <Img fluid={image1.childImageSharp.fluid} />
        <div className="t-index__logo">
          <Logo size="large" />
        </div>
        <div className="t-index__triangle -no1" />
      </div>
      <div className="t-index__section -no2">
        <div className="t-index__triangle -no2" />
        <Img fluid={image2.childImageSharp.fluid} />
      </div>
      <div className="t-index__section -no3">
        <div className="t-index__triangle -no3 -left" />
        <Img fluid={image3.childImageSharp.fluid} />
        <div className="t-index__triangle -no3 -right" />
      </div>
      <div className="t-index__section -before_no4">
        <div className="t-index__triangle -before_no4" />
      </div>
      <div className="t-index__section -no4">
        <Img fluid={image4.childImageSharp.fluid} />
      </div>
      <div className="t-index__section -no5">
        <Img fluid={image5.childImageSharp.fluid} />
      </div>
      <div className="t-index__section -no6">
        <Img fluid={image6.childImageSharp.fluid} />
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "index" } }) {
      frontmatter {
        image1 {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 932) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image4 {
          childImageSharp {
            fluid(maxWidth: 432) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image5 {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image6 {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
